import { useMemo } from 'react';
import Link from './link';
import Header from './header';
import UptimeRobot from './uptimerobot';

function App() {

  const apikeys = useMemo(() => {
    const { ApiKeys } = window.Config;
    if (Array.isArray(ApiKeys)) return ApiKeys;
    if (typeof ApiKeys === 'string') return [ApiKeys];
    return [];
  }, []);

  return (
    <>
      <Header />
      <div className='container'>
        <div id='uptime'>
          {apikeys.map((key) => (
            <UptimeRobot key={key} apikey={key} />
          ))}
        </div>
        <div id='footer'>
          <p>可用性检测频率 5 分钟</p>
          <p>Copyright © 2018-{new Date().getFullYear()}
            <Link to='https://sailoud.com/' text=' Sailoud LLC ' />
            All rights reserved.</p>
        </div>
      </div>
    </>
  );
}

export default App;
